section.pricing {
    background: #007bff;
    background: linear-gradient(to right, #0062E6, #33AEFF);
  }
section.iELTS {
    background: #E31837;
    background: linear-gradient(to left, rgb(209, 2, 23), #E31837);
  }

.droneP {
    /* background: #007bff; */
    background-image: url("../../assets/img/drone-iamge.jpg") !important;
    background-size: 100% 100%  !important;
    background-repeat: no-repeat  !important;
    background-position: top;

    /* background: linear-gradient(to right, #0062E6, #33AEFF); */
  }
.virtualReality {
    /* background: #007bff; */
    background-image: url("../../assets/img/vr-image.jpg") !important;
    background-size: 100%   100% !important;
    background-repeat: no-repeat  !important;
    background-position: top !important;
    

    /* background: linear-gradient(to right, #0062E6, #33AEFF); */
  }
.threed {
    /* background: #007bff; */
    background-image: url("../../assets/img/3d2.png") !important;
    background-size: auto !important;
    background-repeat: no-repeat  !important;
    /* background-position: left !important; */
    background-position-x: 1px !important;
    background-color: #e7e7e77a !important;


    /* background: linear-gradient(to right, #0062E6, #33AEFF); */
  }
.electronics {
    /* background: #007bff; */
    background-image: url("../../assets/img/circuit.png") !important;
    background-size: auto !important;
    background-repeat: no-repeat  !important;
    

    /* background: linear-gradient(to right, #0062E6, #33AEFF); */
  }

  @media only screen and (max-width: 768px) {
    .droneP {
      /* background: #007bff; */
      background-image: url("../../assets/img/drone-iamge.jpg") !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background-position: top;
      /* background: linear-gradient(to right, #0062E6, #33AEFF); */
    }
    .virtualReality {
      /* background: #007bff; */
      background-image: url("../../assets/img/vr-image.jpg") !important;
      background-size: 100%    !important;
      background-repeat: no-repeat  !important;
      background-position: top !important;
      
  
      /* background: linear-gradient(to right, #0062E6, #33AEFF); */
    }
  }
   .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
   .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .pricing hr {
    margin: 1.5rem 0;
  }
  
   .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }
  
   .card-price {
    font-size: 2rem;
    margin: 0;
  }
  
   .card-price .period {
    font-size: 0.8rem;
  }
  
  .pricing ul li {
    margin-bottom: 1rem;
  }
  
  .pricing .text-muted {
    opacity: 0.7;
  }
  
  .card .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
  }
  .paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: #0d6efd;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* width: ; */
    height: 7vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .card .dropdown-toggle {
    background-color: black !important;
  }
  /* Hover Effects on Card */
  
  @media (min-width: 992px) {
     .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
  
     .card:hover .btn {
      opacity: 1;
    }
  }

  .form-input{
    padding: 5px !important;
    overflow: block !important;
  }

  .branch-btn{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #33AEFF !important; */
    color: rgb(92, 92, 92) !important;
  }
  .branch-btn:active{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #fff !important; */
    color:rgba(10, 148, 219, 1) !important;
  }
  .branch-active{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #fff !important; */
    color: #0062E6 !important;
  }