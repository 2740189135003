.bc-brand-logo {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    display: inline-block;
    width: 0;
    height: 34px;
    padding: 0 0 0px 121px;
    margin: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url("./bc-logo-white.svg"),none
}
.w-content{
  width: fit-content;
text-align: center !important;
}

.bc-brand-logo::before {
  width: 105px;
  height: 30px;
}

.ielts-brand-logo {
  font: 0/0 a;
color: transparent;
text-shadow: none;
background-color: transparent;
display: inline-block;
width: 134px;
height: 34px;
padding: 0 0 0px 121px;
margin: 0;
overflow: hidden;
background-repeat: no-repeat;
background-image: url("./download.svg"),none;
background-size: contain;
margin-left: 20px;}

.iELTS {
  background: #E31837;
  background: linear-gradient(to left, rgb(209, 2, 23), #E31837);
}