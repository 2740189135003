section.codingprice {
    background: #5b5c5c;
    background: linear-gradient(to right, #1d1d1d, #8a8a8a);
  }
  
  .codingprice .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .codingprice hr {
    margin: 1.5rem 0;
  }
  
  .codingprice .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
  }
  
  .codingprice .card-price {
    font-size: 2rem;
    margin: 0;
  }
  
  .codingprice .card-price .period {
    font-size: 0.8rem;
  }
  
  .codingprice ul li {
    margin-bottom: 1rem;
  }
  
  .codingprice .text-muted {
    opacity: 0.7;
  }
  
  .codingprice .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
  }
  .paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: #0d6efd;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* width: ; */
    height: 7vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  
  .codingprice .dropdown-toggle {
    background-color: black !important;
  }
  /* Hover Effects on Card */
  
  @media (min-width: 992px) {
    .codingprice .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
  
    .codingprice .card:hover .btn {
      opacity: 1;
    }
  }

  .form-input{
    padding: 5px !important;
    overflow: block !important;
  }

  .branch-btn{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #33AEFF !important; */
    color: rgb(92, 92, 92) !important;
  }
  .branch-btn:active{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #fff !important; */
    color:rgba(10, 148, 219, 1) !important;
  }
  .branch-active{
    font-size: 80%;
    /* border-radius: 5rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 1 !important;
    transition: all 0.2s;
    /* background-color: #fff !important; */
    color: #0062E6 !important;
  }