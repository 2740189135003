.loginsideimg{
    
    position: relative;
    /* background-color: #ebf1f7; */
    background: url("../../../assets/svg/undraw_Login_re_4vu2.svg") no-repeat center center;
    background-size: contain;
    /* padding-top: 8rem; */
    /* padding-bottom: 8rem; */
    min-height: 70vh;
    background-position-x: left;

}
.signupsideimg{
    
    position: relative;
    /* background-color: #ebf1f7; */
    background: url("../../../assets/svg/undraw_Sign_in_re_o58h.svg") no-repeat center center;
    background-size: contain;
    /* padding-top: 8rem; */
    /* padding-bottom: 8rem; */
    background-position-x: left;
    min-height: 70vh;

}

.header{
    /* font-family: Euclid_Circular_B_Bold !important; */
    color: #2e7ab8;
}