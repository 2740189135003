/********** common setting **********/
h2 {
    font-size: 2.8rem !important;
    font-weight: 700 !important;
}

img {
    display: block !important;
    max-width: 100% !important;
}

a,
button {
    cursor: pointer !important;
}

.jumbotron {
    margin-bottom: 0 !important;
}

.atlas-cta {
    border-radius: 22px !important;
    padding: 12px 30px !important;
    font-weight: 700 !important;
    transition: .3s ease-in-out !important;
}

.atlas-cta:hover {
    text-decoration: none !important;
    transform: translateY(-5px) !important;
}

.atlas-cta-wide {
    width: 100% !important;
}

.cta-green {
    background: #00FFAD !important;
    color: #192440 !important;
}

.cta-green:hover {
    color: #ffffff !important;
    background: #00e69c !important;
}

.cta-blue {
    background: #192440 !important;
    color: #ffffff !important;
}

.cta-blue:hover {
    color: #ffffff !important;
    background: #121a2e !important;
}

.cta-ghost {
    border: 2px solid #192440 !important;
    color: #192440 !important;
}

.cta-ghost:hover {
    color: #ffffff !important;
    background: #121a2e !important;
}

#banner {
    background-size: cover !important;
}

/********** banner **********/
#banner header {
    overflow: hidden !important;
}

#banner header img {
    max-width: 80px !important;
}

/********** feature (skew background) **********/
.feature img {
    width: 100% !important;
    max-width: 480px !important;
}

#feature-first {
    background: linear-gradient(168deg, #ffffff 55%, #00FFAD 0) !important;
}
#feature-second {
    background: linear-gradient(168deg, #00FFAD 55%, #ffffff 0) !important;
}
#feature-curved-right {
    background-color: #00ffad;
    clip-path: ellipse(123% 100% at 114.24% 0%);
}

#feature-curved-left {
    background-color: #00ffad;
    clip-path: ellipse(123% 100% at 14.96% 0%);
}

#feature-last {
    background: #00FFAD !important;
}

/********** price table **********/
#price-table {
    padding-top: 4rem !important ;
    padding-bottom: 4rem !important ;
}

#price-table__premium {
    background: #192440 !important;
    color: #ffffff !important;
}

#price-table ul li {
    padding: 5px 0 !important;
}

/********** contact **********/
#contact {
    background-size: cover !important;
}

#contact ul li {
    padding: 3px 0 !important;
}

#contact form {
    color: #00FFAD !important;
}

#contact form input,
#contact form textarea {
    background: transparent !important;
    border: 1px solid #00FFAD !important;
    color: #ffffff !important;
}

/********** copyright **********/
#copyright {
    background: #192440 !important;
    padding: 2rem 3rem !important;
}

#copyright #social-media a {
    width: 40px !important;
    height: 40px !important;
    border-radius: 99% !important;
    background: #ffffff !important;
    transition: .4s ease !important;
}

#copyright #social-media a i {
    color: #192440 !important;
    font-size: 1.2rem !important;
    line-height: 40px !important;
}

#copyright #social-media a:hover {
    background: #00FFAD !important;
}

#copyright #social-media a:hover i {
    color: #ffffff !important;
}

/********** RWD **********/
@media (max-width: 575px) {
    #banner h1 {
        font-size: 3.6rem !important;
    }
}

.svg-icons {
    max-width: 40% !important;
    padding: 1.2rem !important;
}