.features-icons {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.ro-text-green{
    color: #36ce36 !important;
}

.caption {
    position: absolute !important;
    right: 15% !important;
    top: 17.25% !important;
    left: 15% !important;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
    color: #fff !important;
    text-align: right !important;
    /* background-color: #0dcaf054; */

}
.caption-box{
    background-color: #41464b47;
    padding: 2rem;
    width: max-content !important;
    position: absolute !important;
    right: 0% !important;
}
.caption-box > h3 {
    
    color: #fff !important;
    text-align: right !important;
    font-weight: 700;
    font-size: 3.0rem;
    /* background-color: #0dcaf054; */
}
.caption-box > p {
    
    color: fafafa!important;
    text-align: right !important;
    font-weight: 700;
    font-size: 1.0rem;
    /* background-color: #0dcaf054; */
}

.carousel-item > img {
    /* max-height: 60% !important; */
    /* height: fit-content !important; */
 
}

.carousel-item{
    max-height: 100vh !important;
}

@media only screen and (max-width: 640px) {
    .caption-box > h3 {
    
        color: #fff !important;
        text-align: center !important;
        font-weight: 600;
        font-size: 2.0em;
        /* background-color: #0dcaf054; */
    }

    .caption-box{
        background-color: #41464b47;
        padding: 2rem;
        width: fit-content !important;
        position: absolute !important;
        right: 0% !important;
    }

    .caption {
        position: absolute !important;
        right: 5% !important;
        top: 0.25% !important;
        left: 15% !important;
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
        color: #fff !important;
        text-align: right !important;
        /* background-color: #0dcaf054; */
    
    }
    .caption-box > p {
        display: none !important;
        color: fafafa!important;
        text-align: right !important;
        font-weight: 700;
        font-size: 1.0rem;
        /* background-color: #0dcaf054; */
    }
  }