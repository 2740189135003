.navlink {
	padding: 10px 20px;
	color: #949494;  font-weight: 700;  font-size: 16px;
}
.active {
	background-color: transparent;
	color: #356DE8;
}
.navlink:hover,
.active :hover {
	color: #306AE8;
}