body {
    /* background:#000; */
  }
  .jnow{
      opacity: 1 !important;
      font-size: 80% !important;
      border-radius: 5rem;
      letter-spacing: .1rem;
      font-weight: bold;
      padding: 1rem;
      transition: all 0.2s;
  }
  .bg-opacity-2{
      opacity: 0.9;
  }
  .cater3-movingBG {
      /* height: 80vh !important; */
      background-image: url(../../assets/img/MG9545.jpg);
      /* background-size: 125% 125%; */
      background-size: contain;
      background-repeat: repeat-x;
      animation: animatedBackground 15s linear alternate infinite;
  }
  
  @keyframes animatedBackground {
      0% { background-position: 0 0; }
      50% { background-position: 50% 0; }
      100% { background-position: 0 0; }
  }
  
  .flyinTxtCont {
        /* height: 100%; */
        /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-center;
        padding-bottom: 10%;
        margin-left: 2em; */
  }
  .flyIn {
      /* color:rgb(28, 35, 41); */
      /* font-family:'Raleway'; */
      /* text-transform:uppercase; */
      /* line-height:1.2em; */
      /* position:relative; */
      /* align-content: center; */
      /* text-shadow: 2px 2px 40px rgba(233, 233, 233, 0.7); */

  }


  .lineOne {
      transition-delay:.2s; 
      transition:.4s ease in;
      animation: txtFlyIn .3s linear;
  }
  .lineTwo {
        transition-delay:.4s; 
        transition:.6s ease in;
        animation: txtFlyIn .6s linear;
  }
  .lineThree {
        transition-delay:.6s; 
        transition:.8s ease in;
        animation: txtFlyIn .9s linear;
  }
  .lineOne, .lineTwo, .lineThree {
        font-size:3rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
    }
  .lineFour {
          transition-delay:2s; 
          transition:2s ease in;
          animation: fadeIn 2s linear;
          /* width: 100%; */
          /* background-color:rgba(255,255,255,0.9); */
          display:inline-block;
          color:#000;
          box-size:border-box;
          /* max-width:63%;
          max-width: 22em; */
          /* font-size: 32px; */
          padding: .2em .5em;
          margin-top: 30px;
          align-items: flex-center;
          text-align: center;

  }
  @media only screen and (max-width: 640px){
    .cater3-movingBG {
        height: 100% !important;
        background-image: url(../../assets/img/MG9545.jpg);
        /* background-size: 125% 125%; */
        background-size: contain;
        background-repeat: repeat-x;
        animation: animatedBackground 15s linear alternate infinite;
    }
    .flyIn {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        /* font-size: 2.0rem;
        font-weight: 600; */
  
    }  
    .lineOne, .lineTwo, .lineThree {
        font-size:2.0rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
    }
  }

  @keyframes fadeIn{
      0% { opacity:0;}
      100% { opacity:1;}
  }
  @keyframes txtFlyIn{
      0% { transform:translateX(-100%); }
      100% { transform:translateX(0%); }
  }

  .slide-wrapper {
    width: 100%; /* Or a specific width */
  justify-content: center;
  align-items: center;
}

.slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as needed */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px; /* Ensure consistent image size */
  height: 150px; /* Ensure consistent image size */
  overflow: hidden;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Prevents image distortion */
}
